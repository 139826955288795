import React, { Component } from 'react';
import './DummyVideoRow.css';
import DummyVideoRowItem from './DummyVideoRowItem';




class DummyVideoRow extends Component {

  render() {
    return (
      <div className = "videosRow-MainContainer">
        <div className = "dummyVideosRow-Row">
          
        </div>
      </div>
    );
  }
}

export default DummyVideoRow;
