import './PlatformSidebarMenuItemsHyperace.css';
import PlatformSidebarMenuItemsHyperace  from './PlatformSidebarMenuItemsHyperace';
import CreateAccountButton from './CreateAccountButton';
import AddFundsButton from './AddFundsButton.js';
import ImportFromPasswordButton from './ImportFromPasswordButton.js';
import Wallet from './Wallet';
import React, { Component } from 'react';
import styled from 'styled-components';
import * as scriptUtils from './utils/scriptUtils';
import { ConnectButton } from '@rainbow-me/rainbowkit';
const axios = require('axios');

class PlatformSidebarHyperace extends Component {
  constructor(props) {
    super(props);
    this.toggleAccountSettingsShown = this.toggleAccountSettingsShown.bind(this);
    this.setCopyMessage = this.setCopyMessage.bind(this);
    this.copyAddress = this.copyAddress.bind(this);

    this.state = {
      copyMessage: "",
      isShowingAccountSettings: false,
      address: '',
      balance: 0,
      open: false,
      USDPrice: "Invalid coinbase exchange rate request",
    }
  }

  async componentDidMount() {
    let balance = await Wallet.getBalance();
    let address = Wallet.address();
    let open = false;
    let data = await axios.get("https://api.coinbase.com/v2/exchange-rates", { params: { currency: "MATIC" } });
    if(data != null) {
      this.setState({
        USDPrice: data["data"]["data"]["rates"]["USD"]
      })
    }
    this.setState({
      address,
      balance,
      open,
    });
  }

  async componentWillReceiveProps() {
    let balance = await Wallet.getBalance();
    let address = Wallet.address();
    let open = false;
    this.setState({
      address,
      balance,
      open,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  toggleAccountSettingsShown() {
    this.setState({
      isShowingAccountSettings: !this.state.isShowingAccountSettings,
    });
  }

  copyAddress() {
    navigator.clipboard.writeText(this.state.address);
    this.setCopyMessage("Copied");
    this.timer = setTimeout(() => this.setCopyMessage(""), 2000);
  }

  setCopyMessage(message) {
    this.setState({
      copyMessage: message,
    });
  }

  render() {
    let profileImage = '';
    if(this.state.address !== "" && this.state.address !== null){
      profileImage = scriptUtils.getProfileImage(this.state.address);
    }
    return (
      <div className="inline-flex fixed top-0 z-10 h-16 items-center w-full justify-between position:fixed left-0 right-0 dark:bg-custom-black bg-white shadow-sm">
        <PlatformSidebarMenuItemsHyperace />
        <div className="mr-10 sm:text-lg lg:text-lg xs:text-xs">
          <ConnectButton
            showBalance={false}
            chainStatus="none"
          />
        </div>
      </div>
    );
  }
}


export default PlatformSidebarHyperace;