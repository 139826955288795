import React, { Component, useState, useRef, useEffect } from 'react';
import './VideosRowItem.css';
import './VideoPlayerItem.css';
import { IoArrowBack, IoArrowRedoOutline } from "react-icons/io5";
import { IoMdHeartEmpty, IoMdHeart, IoIosCheckmarkCircle } from 'react-icons/io';
import Wallet from './Wallet';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';
import axios from 'axios';
import Web3 from 'web3';
import { useHistory } from "react-router-dom";
const { REACT_APP_PUBLIC_KEY } = process.env;

function HyperaceVideosRowItem(props) {
  const history = useHistory();
  const [copyMessage, setCopyMessage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isHeartClicked, setIsHeartClicked] = useState(props.video.userVote);
  const [totalListenTime, setTotalListenTime] = useState(0);
  const [totalUpvotes, setTotalUpvotes] = useState(props.video.totalUpvotes);
  const [videoElement, setVideoElement] = useState(null);
  const itemPlayer = useRef(null);

  useEffect(() => {
    setIsHeartClicked(props.video.userVote);
  }, [props.video.userVote]);

  useEffect(() => {
    setTotalUpvotes(props.video.totalUpvotes);
  }, [props.video.totalUpvotes]);

  useEffect(() => {
    let newVideoElement = (
      <video
        ref={itemPlayer}
        onTimeUpdate={onPlaying}
        controls
        playsInline
        width="100%"
        key={props.video.decryptedBlobURL}
      >
        <source src={props.video.decryptedBlobURL} type="video/mp4" />
      </video>
    );
    setVideoElement(newVideoElement);
  }, [props.video.decryptedBlobURL])

  function handleClickVideo() {
    let route = "/media/" + props.video.tokenId.toString();
    history.push(route);
  }

  function onClickShareLink(e) {
    e.stopPropagation();
    let shareLink = window.location.origin + "/media/" + props.video.tokenId.toString();
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);
    setCopyMessage("Copied");
    loggingUtils.logEvent(
      props.address != null ? props.address : 'wallet_unconnected_user',
      'videos',
      'videos_share_item',
      'info',
      "https://the402.xyz",
      {
        video: props.video
      },
    );
    setTimeout(() => {
      setIsCopied(false);
      setCopyMessage("");
    }, 2000);
  }

  function onUserClickedConnectWallet() {
    loggingUtils.logEvent(
      'wallet_unconnected_user',
      'videos',
      'videos_like_item_with_wallet_disconnected',
      'info',
      "https://the402.xyz",
      {
        video: props.video
      },
    );
    let buttons = document.getElementsByTagName("button");
    for(let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      if(button.outerText === "Connect Wallet") {
        button.click();
      }
    }
  }

  async function onClickHeart() {
    if(props.address == null) {
      onUserClickedConnectWallet();
    } else {
      let uriSplit = props.video.uri.split("/");
      let tokenCID = uriSplit[uriSplit.length - 1];
      if(!isHeartClicked) {
        await encryptUtils.logUpvoteHyperace(tokenCID, props.address);
        loggingUtils.logEvent(
          props.address != null ? props.address : 'wallet_unconnected_user',
          'videos',
          'videos_like_item',
          'info',
          "https://the402.xyz",
          {
            video: props.video
          },
        );
        setIsHeartClicked(true);
        setTotalUpvotes(totalUpvotes + 1);
      } else {
        await encryptUtils.logRemoveUpvoteHyperace(tokenCID, props.address);
        loggingUtils.logEvent(
          props.address != null ? props.address : 'wallet_unconnected_user',
          'videos',
          'videos_unlike_item',
          'info',
          "https://the402.xyz",
          {
            video: props.video
          },
        );
        setIsHeartClicked(false);
        setTotalUpvotes(totalUpvotes - 1);
      }
    }
  }

  function onPlaying() {
    let newTotalListenTime = 0;
    for(let i = 0; i < itemPlayer.current.played.length; i++) {
      newTotalListenTime += itemPlayer.current.played.end(i) - itemPlayer.current.played.start(i)
    }
    let halfDuration = itemPlayer.current.duration / 2;
    if(newTotalListenTime > halfDuration && totalListenTime < halfDuration) {
      let uriSplit = props.video.uri.split("/");
      let tokenCID = uriSplit[uriSplit.length - 1];
      console.log("Logging impression for " + props.address);
      encryptUtils.logImpressionHyperace(tokenCID, props.address);
      loggingUtils.logEvent(
        props.address,
        'videos',
        'videos_log_impression',
        'info',
        "https://the402.xyz",
        {
          video: props.video
        },
      );
    }
    if(totalListenTime !== newTotalListenTime) {
      setTotalListenTime(newTotalListenTime);
    }
  }

  return (
    <div className="border border-gray-200 dark:border-gray-800 max-w-md mx-auto mb-8 dark:bg-black bg-white rounded-xl" onClick={handleClickVideo}>
      <div className="p-4 inline-flex flex items-center">
        <div>
          <img className="h-8 w-8" src={props.video.creatorProfileImage}/>
        </div>
        <div className="mx-2">
          <div className="text-sm font-medium text-black dark:text-white">
            {props.video.displayedCreatorAddress}
          </div>
        </div>
      </div>
      <div>
        {videoElement}
      </div>
      <div className="p-4 flex">
        <div className="w-9/12 text-sm font-medium text-black dark:text-white">
          {props.video.description.replaceAll("_", " ")}
        </div>
        <div className="flex inline-flex w-14 ml-1">
          {
            isHeartClicked
              ?
            <IoMdHeart
              onClick={async (e) => {
                e.stopPropagation();
                await onClickHeart();
              }}
              color="red"
              className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
            />
              :
            <IoMdHeartEmpty
              onClick={async (e) => {
                e.stopPropagation();
                await onClickHeart();
              }}
              className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
            />
          }
          <div className="ml-2 text-sm font-medium text-black dark:text-white">
            {totalUpvotes}
          </div>
        </div>
        {
          isCopied
            ?
          <IoIosCheckmarkCircle className="text-green-500 text-lg" />
            :
          <IoArrowRedoOutline
            className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
            onClick={onClickShareLink}
            onMouseEnter={() => setCopyMessage("Share")}
            onMouseLeave={() => setCopyMessage("")}
          />
        }
        <div className="videosRowItemCopyMessage">
          {copyMessage}
        </div>
      </div>
    </div>
  );
}

export default HyperaceVideosRowItem;