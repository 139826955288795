import { contractAddress, mainnetContractAddress } from "./config";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import axios from "axios";
const { REACT_APP_PUBLIC_KEY, REACT_APP_PRIVATE_KEY, REACT_APP_API_URL, REACT_APP_PROD_API_URL, REACT_APP_IS_MAINNET } =
  process.env;

let web3;
if(REACT_APP_IS_MAINNET !== "testnet") {
  web3 = createAlchemyWeb3(REACT_APP_PROD_API_URL);
} else {
  web3 = createAlchemyWeb3(REACT_APP_API_URL);
}

const contract = require("./artifacts/contracts/PPV.sol/PPV.json");
let ppvContract;
if(REACT_APP_IS_MAINNET !== "testnet") {
  ppvContract = new web3.eth.Contract(contract.abi, mainnetContractAddress);
} else {
  ppvContract = new web3.eth.Contract(contract.abi, contractAddress);
}

let usedContractAddress;
if(REACT_APP_IS_MAINNET !== "testnet") {
  usedContractAddress = mainnetContractAddress;
} else {
  usedContractAddress = contractAddress;
}

export async function getCurrentGasPrice() {
  let data;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    data = await axios.get("https://gasstation-mainnet.matic.network/v2");
  } else {
    data = await axios.get("https://gasstation-mumbai.matic.today/v2");
  }
  if (data != null) {
    console.log(data);
    return parseInt(data["data"]["fast"]["maxPriorityFee"] * 1000000000);
  } else {
    return null;
  }
}

export async function publishPPV(tokenCID, address, authUUID) {
  const nonce = await web3.eth.getTransactionCount(
    REACT_APP_PUBLIC_KEY,
    "pending"
  );
  const maxGasPrice = await getCurrentGasPrice();
  console.log("publish " + nonce);
  const tx = {
    from: REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: ppvContract.methods
      .publishUpcomingMintInfo(tokenCID, 1, 0, 0, 0, address, 1, 0, 0, authUUID)
      .encodeABI(),
  };

  try {
    let signedTx = await web3.eth.accounts.signTransaction(tx, REACT_APP_PRIVATE_KEY);
    let sendTxPromise = new Promise(function (resolve, reject) {
      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve();
        });
    });
    await sendTxPromise;
    return true;
  } catch (err) {
    return false;
  }
}

export async function publishUpcomingMintWithInfo(
  tokenCID,
  creatorShares,
  holderShares,
  impressionFee,
  creatorRoyaltyPercentage,
  creatorAddress,
  mintLimit,
  mintPrice,
  mediaType,
  authUUID
) {
  const nonce = await web3.eth.getTransactionCount(
    REACT_APP_PUBLIC_KEY,
    "pending"
  );
  const maxGasPrice = await getCurrentGasPrice();
  console.log("publish " + nonce);
  console.log(tokenCID);
  console.log(creatorShares);
  console.log(holderShares);
  console.log(web3.utils.toWei(impressionFee));
  console.log(creatorRoyaltyPercentage);
  console.log(creatorAddress);
  console.log(mintLimit);
  console.log(web3.utils.toWei(mintPrice));
  console.log(mediaType);
  console.log(authUUID);
  const tx = {
    from: REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: ppvContract.methods
      .publishUpcomingMintInfo(
        tokenCID,
        creatorShares,
        holderShares,
        web3.utils.toWei(impressionFee),
        creatorRoyaltyPercentage,
        creatorAddress,
        mintLimit,
        web3.utils.toWei(mintPrice),
        mediaType,
        authUUID
      )
      .encodeABI(),
  };

  try {
    let signedTx = await web3.eth.accounts.signTransaction(tx, REACT_APP_PRIVATE_KEY);
    let sendTxPromise = new Promise(function (resolve, reject) {
      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve();
        });
    });
    await sendTxPromise;
    return true;
  } catch (err) {
    return false;
  }
}

export async function mintFreePPV(tokenID, publicKey) {
  const nonce = await web3.eth.getTransactionCount(
    REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();
  console.log("mint " + nonce);
  //the transaction
  const tx = {
    from: REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: ppvContract.methods.mintTokens(1, tokenID, publicKey).encodeABI(),
    value: 0,
  };

  console.log(tx);
  try {
    let sendTxPromise = new Promise(function (resolve, reject) {
      web3.eth.accounts
        .signTransaction(tx, REACT_APP_PRIVATE_KEY)

        .then((signedTx) => {
          web3.eth.sendSignedTransaction(
            signedTx.rawTransaction,
            function (err, hash) {
              if (!err) {
                console.log(
                  "The hash of your transaction is: ",
                  hash,
                  "\nCheck Alchemy's Mempool to view the status of your transaction!"
                );
                resolve(true);
              } else {
                console.log(
                  "Something went wrong when submitting your transaction:",
                  err
                );
                resolve(false);
              }
            }
          );
        })
        .catch((err) => {
          console.log(" Promise failed:", err);
          reject(false);
        });
    });
    let didApprove = await sendTxPromise;
    return didApprove;
  } catch (err) {
    return false;
  }
}

export async function openMint(tokenId) {
  const nonce = await web3.eth.getTransactionCount(
    REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();
  console.log("open " + nonce);
  console.log(tokenId);

  //the transaction
  const tx = {
    from: REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: ppvContract.methods.setIsReadyForMint(tokenId).encodeABI(),
  };

  try {
    let sendTxPromise = new Promise(function (resolve, reject) {
      web3.eth.accounts
        .signTransaction(tx, REACT_APP_PRIVATE_KEY)
        .then((signedTx) => {
          web3.eth.sendSignedTransaction(
            signedTx.rawTransaction,
            function (err, hash) {
              if (!err) {
                console.log(
                  "The hash of your transaction is: ",
                  hash,
                  "\nCheck Alchemy's Mempool to view the status of your transaction!"
                );
                resolve(true);
              } else {
                console.log(
                  "Something went wrong when submitting your transaction:",
                  err
                );
                resolve(false);
              }
            }
          );
        })
        .catch((err) => {
          console.log(" Promise failed:", err);
          reject(false);
        });
    });
    let didApprove = await sendTxPromise;
    return didApprove;
  } catch (err) {
    return false;
  }
}