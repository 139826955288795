import React, { Component } from 'react';
import './VideosHome.css';
import HyperaceVideosRow from './HyperaceVideosRow';
import DummyVideoRow from './DummyVideoRow';

class HyperaceVideosHome extends Component {
  render() {
    return (
      <div className="mx-auto max-w-2xl flex items-center justify-center border-white-600 dark:border-custom-black border-x-2">
        <div>
        <div className="mx-auto mt-16 flex items-center justify-center">
          <input
            type="text"
            value={this.props.searchText}
            onChange={this.props.onChangeSearchText}
            className="rounded-lg my-8 bg-gray-200 dark:text-gray-500 dark:bg-custom-black w-72 p-2 "
            placeholder = "Search"
          />
        </div>
          <div>
            <HyperaceVideosRow address={this.props.address} mintedVideos={this.props.mintedVideos} />
          </div>
        </div>
      </div>
    );
  }
}

export default HyperaceVideosHome;