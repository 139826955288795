import React, { Component } from 'react';
import './CreatorUploadDescriptionForm.css';

class CreatorUploadDescriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (props.params && props.params.description ? props.params.description : "")
    };

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.setState({value: event.target.value});
    this.props.setDescription(event.target.value);
  }

  render() {
    return (
      <div>
        <label className="text-black">
          <input placeholder="Describe your video" className="placeholder-gray-500 font-medium w-full p-4 mt-8 rounded-lg flex justify-center items-center h-12 dark:text-white bg-gray-200 dark:bg-zinc-900" value={this.state.value} onChange={this.handleChange} />
        </label>
      </div>
    );
  }
}

export default CreatorUploadDescriptionForm;
