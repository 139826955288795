import './PlatformSidebarMenuItemsHyperace.css';
import { useHistory, useLocation } from "react-router-dom";
import the402logo from './the402logo.png';

function PlatformSidebarMenuItemsHyperace(props) {
  const history = useHistory();
  const location = useLocation();
  function handleClickMarketplace() {
    history.push("/marketplace")
  }
  function handleClickVideos() {
    history.push("/videos")
  }
  function handleClickUpload() {
    history.push("/upload")
  }

  return (
    <div className="flex items-center">
      <div className = {location.pathname === "/videos" || location.pathname === "/videos/video" ? "ml-10 font-medium text-gray-600 dark:text-gray-400 cursor-pointer" : "ml-10 font-medium text-gray-600 dark:text-gray-400 cursor-pointer"} onClick = {handleClickVideos}>
        <div>
          Explore
        </div>
      </div>
      <div className = {location.pathname === "/" ? "bg-black dark:bg-white h-10 w-24 justify-center items-center rounded-lg flex ml-10 font-medium text-white dark:text-black cursor-pointer" : "bg-black dark:bg-white h-10 w-24 justify-center items-center rounded-lg flex ml-10 font-medium text-white dark:text-black cursor-pointer"} onClick = {handleClickUpload}>
        <div>
          Upload
        </div>
      </div>
    </div>
  );
}

export default PlatformSidebarMenuItemsHyperace;
