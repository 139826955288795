import React, { Component } from 'react';
import './VideosRowItem.css';
import './VideoPlayerItem.css';
import Wallet from './Wallet';
import PlatformMusicPlayer from './PlatformMusicPlayer';
import * as encryptUtils from './encryptDecryptUtils';
import * as loggingUtils from './utils/loggingUtils';
import * as scriptUtils from './utils/scriptUtils';
import axios from 'axios';
import PlatformSidebarHyperace from './PlatformSidebarHyperace';
import { IoMdHeartEmpty, IoMdHeart, IoIosCheckmarkCircle } from 'react-icons/io';
import { IoArrowBack, IoArrowRedoOutline } from "react-icons/io5";
import Onboarding from './Onboarding.js';
import AddFunds from './AddFunds.js';
import ImportFromPassword from './ImportFromPassword';
import HyperaceConnectWalletModal from './HyperaceConnectWalletModal';

const { REACT_APP_PUBLIC_KEY, REACT_APP_PRIVATE_KEY } = process.env;

class HyperaceItemDedicatedPage extends Component {
  constructor(props) {
    super(props);
    this.fetchInfo = this.fetchInfo.bind(this);
    this.litDecrypt = this.litDecrypt.bind(this);
    this.selectModal = this.selectModal.bind(this);
    this.onClickShareLink = this.onClickShareLink.bind(this);
    this.onClickHeart = this.onClickHeart.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.setCopyMessage = this.setCopyMessage.bind(this);

    this.itemPlayer = React.createRef();

    this.state = {
      totalListenTime: 0,
      isHeartClicked: false,
      isCopied: false,
      copyMessage: "",
      itemFile: null,
      item: null,
      videoElement: null,
      modalShown: '',
    }
  }

  selectModal(modal) {
    this.setState({
      modalShown: modal,
    });
  }

  async componentDidMount() {
    let urlSplit = window.location.href.split("/");
    let tokenId = urlSplit[urlSplit.length - 1];
    if(tokenId.includes("?autorefresh=true")) {
      tokenId = tokenId.split("?")[0];
      let self = this;
      async function waitUntilLoaded() {
        return await new Promise(resolve => {
          const interval = setInterval(async () => {
            if (self.state.item != null) {
              resolve();
              clearInterval(interval);
            } else {
              await self.fetchInfo();
            }
          }, 2000);
        });
      }
      waitUntilLoaded();
    } else {
      await this.fetchInfo();
    }
  }

  async fetchInfo() {
    let urlSplit = window.location.href.split("/");
    let tokenId = urlSplit[urlSplit.length - 1];
    if(tokenId.includes("?")) {
      tokenId = tokenId.split("?")[0];
    }
    let item = await encryptUtils.getNFTDetails(tokenId);
    let displayedCreatorAddress = item.creatorAddress;
    if(item.creatorAddress != null) {
      displayedCreatorAddress = item.creatorAddress.substring(0, 5) + "..." + item.creatorAddress.substring(item.creatorAddress.length - 5)
    }
    item["displayedCreatorAddress"] = displayedCreatorAddress;
    item["creatorProfileImage"] = scriptUtils.getProfileImage(item.creatorAddress);
    this.setState({
      item,
    });

    await this.litDecrypt();

    let uriSplit = item["uri"].split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    let upvoteInfo = await encryptUtils.getHyperaceLeaderboardData(tokenCID, this.props.account != null ? this.props.account.address : null);
    item["totalUpvotes"] = upvoteInfo["totalUpvotes"];
    item["userVote"] = upvoteInfo["userVote"];
    item["displayedCreatorAddress"] = await encryptUtils.getENS(item.creatorAddress);
    this.setState({
      item,
      isHeartClicked: upvoteInfo["userVote"],
    });
    loggingUtils.logEvent(
      this.props.account != null ? this.props.account.address : 'wallet_unconnected_user',
      'hyperace_item_dedicated_page',
      'hyperace_item_dedicated_page_load',
      'info',
      "https://the402.xyz",
      {
        video: item
      },
    );
  }

  async litDecrypt() {
    if(this.state.itemFile != null) {
      return;
    }
    let authSig = await encryptUtils.getAuthSigDedicatedWallet(REACT_APP_PRIVATE_KEY);
    let item = this.state.item;

    let ppvMetadata = await axios.get(item["uri"]);
    let symmetricKeyRaw = ppvMetadata.data.encryptedSymmetricKey;
    let encryptedSymmetricKey = Object.keys(symmetricKeyRaw).map(function(key){
        return symmetricKeyRaw[key];
    });
    encryptedSymmetricKey = Uint8Array.from(encryptedSymmetricKey);
    let encryptedBlob = await fetch(ppvMetadata.data.encryptedURL).then(res => res.blob());
    let decryptedBlob = await encryptUtils.decryptSong(encryptedSymmetricKey, encryptedBlob, item["authUUID"], authSig);
    let videoElement = (
      <video
        ref={this.itemPlayer}
        onTimeUpdate={this.onPlaying}
        controls
        playsInline
        width="100%"
        className="max-h-144"
      >
        <source src={URL.createObjectURL(new Blob([decryptedBlob]))} type="video/mp4" />
      </video>
    );
    this.setState({
      itemFile: decryptedBlob,
      videoElement,
    });
  }

  onPlaying() {
    let totalListenTime = 0;
    for(let i = 0; i < this.itemPlayer.current.played.length; i++) {
      totalListenTime += this.itemPlayer.current.played.end(i) - this.itemPlayer.current.played.start(i)
    }
    let halfDuration = this.itemPlayer.current.duration / 2;
    if(totalListenTime > halfDuration && this.state.totalListenTime < halfDuration) {
      let uriSplit = this.state.item["uri"].split("/");
      let tokenCID = uriSplit[uriSplit.length - 1];
      encryptUtils.logImpressionHyperace(tokenCID, this.props.account.address);
      loggingUtils.logEvent(
        this.props.account.address,
        'hyperace_item_dedicated_page',
        'hyperace_item_dedicated_page_log_impression',
        'info',
        "https://the402.xyz",
        {
          video: this.state.item
        },
      );
    }
    if(this.state.totalListenTime !== totalListenTime) {
      this.setState({
        totalListenTime,
      });
    }
  }

  onClickShareLink(e) {
    if(this.state.item == null) {
      return;
    }
    e.stopPropagation();
    let shareLink = window.location.origin + "/media/" + this.state.item.tokenId.toString();
    navigator.clipboard.writeText(shareLink);
    this.setState({
      isCopied: true,
      copyMessage: "Copied",
    });
    loggingUtils.logEvent(
      this.props.account != null ? this.props.account.address : 'wallet_unconnected_user',
      'hyperace_item_dedicated_page',
      'hyperace_item_dedicated_page_share_link',
      'info',
      "https://the402.xyz",
      {
        video: this.state.item
      },
    );
    setTimeout(() => {
      this.setState({
        isCopied: false,
        copyMessage: "",
      });
    }, 2000);
  }


  onUserClickedConnectWallet() {
    loggingUtils.logEvent(
      'wallet_unconnected_user',
      'hyperace_item_dedicated_page',
      'hyperace_item_dedicated_page_like_item_with_wallet_disconnected',
      'info',
      "https://the402.xyz",
      {},
    );
    let buttons = document.getElementsByTagName("button");
    for(let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      if(button.outerText === "Connect Wallet") {
        button.click();
      }
    }
  }

  async onClickHeart() {
    if(this.state.item == null) {
      return;
    }
    let uriSplit = this.state.item.uri.split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    if(!this.state.isHeartClicked) {
      await encryptUtils.logUpvoteHyperace(tokenCID, this.props.account.address);
      loggingUtils.logEvent(
        this.props.account.address,
        'hyperace_item_dedicated_page',
        'hyperace_item_dedicated_page_like_item',
        'info',
        "https://the402.xyz",
        {
          video: this.state.item
        },
      );
      let item = this.state.item;
      item["totalUpvotes"] = item["totalUpvotes"] + 1;
      this.setState({
        isHeartClicked: true,
        item,
      });
    } else {
      await encryptUtils.logRemoveUpvoteHyperace(tokenCID, this.props.account.address);
      loggingUtils.logEvent(
        this.props.account.address,
        'hyperace_item_dedicated_page',
        'hyperace_item_dedicated_page_unlike_item',
        'info',
        "https://the402.xyz",
        {
          video: this.state.item
        },
      );
      let item = this.state.item;
      item["totalUpvotes"] = item["totalUpvotes"] - 1;
      this.setState({
        isHeartClicked: false,
        item,
      });
    }
  }

  setCopyMessage(message) {
    this.setState({
      copyMessage: message,
    });
  }

  render() {
    return(
      <div className="h-screen">
        <PlatformSidebarHyperace
          modalShown={this.state.modalShown}
          selectModal={this.selectModal}
        />
        {
          this.state.modalShown === 'createAccount'
            ?
          <Onboarding selectModal={this.selectModal} />
            :
          this.state.modalShown === 'addFunds'
            ?
          <AddFunds selectModal={this.selectModal} />
            :
          this.state.modalShown === 'importFromPassword'
            ?
          <ImportFromPassword selectModal={this.selectModal} />
            :
          null
        }
        {
          this.props.account != null
            ?
          <div className="justify-center items-center pt-24">
            {
              this.state.item != null
                ?
                <div className="max-w-5xl mx-auto dark:bg-black rounded-xl shadow-sm">
                  <div className="max-w-5xl">
                    {this.state.videoElement}
                  </div>
                  <div className="max-w-5xl items-center flex p-6">
                    <div className="inline-flex w-10/12">
                      <div>
                        <img className="h-10 w-10 mr-4" src={this.state.item.creatorProfileImage}/>
                      </div>
                      <div className="col-flex">
                        <div className="text-sm font-medium text-black dark:text-white">
                          {this.state.item.displayedCreatorAddress}
                        </div>
                        <div className="text-sm font-medium text-black dark:text-white">
                          {this.state.item.description}
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex ml-12">
                      <div className="w-16 inline-flex">
                      {
                        this.state.isHeartClicked
                          ?
                        <IoMdHeart
                          onClick={async (e) => {
                            e.stopPropagation();
                            await this.onClickHeart();
                          }}
                          color="red"
                          className="mr-2 text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                        />
                          :
                        <IoMdHeartEmpty
                          onClick={async (e) => {
                            e.stopPropagation();
                            await this.onClickHeart();
                          }}
                          className="mr-2 text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                        />
                      }
                      <div className="text-sm font-medium text-black dark:text-white">
                        {this.state.item.totalUpvotes}
                      </div>
                      </div>
                      {
                        this.state.isCopied
                          ?
                        <IoIosCheckmarkCircle className="text-green-500 text-lg" />
                          :
                        <IoArrowRedoOutline
                          className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                          onClick={this.onClickShareLink}
                          onMouseEnter={() => this.setCopyMessage("Share")}
                          onMouseLeave={() => this.setCopyMessage("")}
                        />
                      }
                      <div className="videosRowItemCopyMessage">
                        {this.state.copyMessage}
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="animate-pulse max-w-5xl mx-auto dark:bg-black rounded-xl shadow-sm">
                  <div className="animate-pulse max-w-5xl h-96 bg-gray-300 dark:bg-gray-800 flex items-center justify-center">
                    <div className="font-medium text-gray-600 dark:text-gray-500">
                      Loading
                    </div>
                  </div>
                  <div className="max-w-5xl items-center flex p-6">
                    <div className="inline-flex w-full">
                      <div>
                        <div className="h-10 w-10 mr-4 animate-pulse rounded-full bg-gray-300 dark:bg-gray-800">
                        </div>
                      </div>
                      <div className="col-flex">
                        <div className="text-sm font-medium text-black dark:text-white bg-gray-300 dark:bg-gray-800 h-5 w-24">
                        </div>
                        <div className="text-sm font-medium text-black dark:text-white bg-gray-300 dark:bg-gray-800 mt-1 h-5 w-56">
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex">
                      <IoMdHeartEmpty
                        className="mr-4 text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                      />
                      <IoArrowRedoOutline
                        className="text-gray-500 dark:text-gray-400 text-xl cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
            }
          </div>
            :
          <div>
            <HyperaceConnectWalletModal/>
          </div>
        }
      </div>
    );
  }
}

export default HyperaceItemDedicatedPage;