import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HyperaceVideos from "./HyperaceVideos";
import HyperaceItemDedicatedPage from "./HyperaceItemDedicatedPage";
import CreatorPlatformHypeRace from "./CreatorPlatformHypeRace";
import { useAccount } from "wagmi";

function AppWithRainbow(props) {
  const { data: account } = useAccount();
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/upload"
          render={(props) => <CreatorPlatformHypeRace account={account} />}
        />
        <Route exact path="/videos" render={(props) => <HyperaceVideos account={account} />} />
        <Route exact path="/media/:tokenId"render={(props) => <HyperaceItemDedicatedPage account={account} />} />
      </Switch>
    </Router>
  );
}

export default AppWithRainbow;
