import React, { Component } from 'react';
import './VideosRow.css';
import HyperaceVideosRowItem from './HyperaceVideosRowItem';

class HyperaceVideosRow extends Component {
  render() {
    return (
      <div>
        <div>
          {
            this.props.mintedVideos.map((video, ind) => {
              return (
                <HyperaceVideosRowItem key={ind} video={video} address={this.props.address} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default HyperaceVideosRow;