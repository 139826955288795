import { ConnectButton } from "@rainbow-me/rainbowkit";

function HyperaceConnectWalletModal(props) {
  return (
    <div className="pt-16 justify-center items-center flex h-screen max-w-full mx-auto dark:bg-black bg-white rounded-xl">
      <div className="flex-col items-center justify-center">
        <div className="text-black md:text-6xl sm:text-4xl xs:text-2xl dark:text-white">
          Connect your wallet to view
        </div>
        <div className="w-full flex justify-center mt-6">
          <ConnectButton showBalance={false} chainStatus="none" />
        </div>
      </div>
    </div>
  );
}

export default HyperaceConnectWalletModal;
