import React, { Component } from 'react';
import './CreatorUploadTitleForm.css';

class CreatorUploadTitleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {value: (props.params && props.params.name ? props.params.name.replaceAll('_', ' ') : "")};

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.setState({value: event.target.value});
    this.props.setTitle(event.target.value);
  }

  render() {
    return (
      <form>
        <label>
          <input placeholder="Title" className = "placeholder-gray-500 font-medium w-full p-4 mt-8 rounded-lg flex justify-center items-center h-12 dark:text-white bg-gray-200 dark:bg-zinc-900" type="text" value={this.state.value} onChange={this.handleChange} />
        </label>
      </form>
    );
  }
}

export default CreatorUploadTitleForm;
