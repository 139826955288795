import React, { Component } from 'react';
import './DummyVideoRowItem.css';
import './VideoPlayerItem.css';
import { IoArrowBack } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import Wallet from './Wallet';
import * as encryptUtils from './encryptDecryptUtils';
import axios from 'axios';

class DummyVideoRowItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoBlob: null,
      clickedVideo: false
    }
  }

  returnBack(){
    this.setState({
      clickedVideo: false
    })
  }

  clickedOnVideo(){
    this.setState({
      clickedVideo: true
    });
  }

  render() {
    if(!this.state.clickedVideo){
      return (
        <div className = "videosRowItem-MainContainer">
          <div className = "videosRowThubmnailContainer">
            <img className = "videoRowThubmnail" src={this.props.thumbnail} onClick={() => this.clickedOnVideo()}/>
          </div>
          <div className = "videosRowItem-Title">
              {this.props.name}
          </div>

        </div>
        );
    }else{
      return(
        <div className = "videoItem-Container">
            <div className = "videoItem-Header">
                <IoArrowBack className = "back-arrow" onClick={() => this.returnBack()}/>
            </div>
            <div className = "videoItem-AuctionContents">
                <div className = "videoItem-VideoDescription">
                    <div className = "videoItem-TitleContainer">
                    </div>
                    <div className = "videoItem-VideoImage">
                        <video
                        >
                        </video>
                    </div>
                    <div className = "videoItem-VideoTitle">
                        {this.props.name}
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }
}

export default DummyVideoRowItem;
