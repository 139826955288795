import React from 'react';
import {useDropzone} from 'react-dropzone';
import './FileUpload.css';

function FileUpload(props) {
  let fileArray = [];
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxFiles: 1});
  const files = acceptedFiles.map(function(file) {
    fileArray.push(file);
    return(
      file.type === "video/mp4" || file.type === "video/quicktime" || file.type === "video/x-quicktime" || file.type === "video/avi"
        ?
      <video className="max-h-96" key={file.path} controls playsInline>
        <source src={URL.createObjectURL(file)} type="video/mp4" />
      </video>
        :
      file.type === "audio/mpeg" || file.type === "audio/wav"
        ?
      <audio className="max-h-96" key={file.path} controls playsInline>
        <source src={URL.createObjectURL(file)} type="audio/mpeg" />
      </audio>
        :
      file.type === "image/png" || file.type === "image/jpeg"
        ?
      <img key={file.path} src={URL.createObjectURL(file)} />
        :
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });

  props.setMedia(fileArray);
  return (
    <div className="max-w-lg rounded-lg flex justify-center items-center h-96 bg-gray-200 dark:bg-zinc-900">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
          files.length === 0
            ?
          <div>
            <div className="bg-black dark:bg-white h-16 w-64 font-semibold justify-center items-center rounded-lg flex font-medium text-white dark:text-black cursor-pointer">
              {
                props.isHyperace
                  ?
                "Upload Video"
                  :
                "Upload Video or Song"
              }
            </div>
            <div className="justify-center w-64 mt-2 items-center inline-flex text-gray-500 dark:text-gray-500 font-medium">
              Maximum size 150MB
            </div>
          </div>
            :
          null
        }
      </div>
      <div>
        <div>{files}</div>
      </div>
    </div>
  );
}

export default FileUpload;
