import React, { Component } from "react";
import "./CreatorPlatform.css";
import CreatorUploadHypeRace from "./CreatorUploadHypeRace";
import PlatformSidebarHyperace from "./PlatformSidebarHyperace";
import Onboarding from "./Onboarding.js";
import AddFunds from "./AddFunds.js";
import ImportFromPassword from "./ImportFromPassword";

class CreatorPlatformHypeRace extends Component {
  constructor(props) {
    super(props);
    this.selectModal = this.selectModal.bind(this);

    this.state = {
      modalShown: "",
    };
  }

  selectModal(modal) {
    this.setState({
      modalShown: modal,
    });
  }

  render() {
    return (
      <div className="h-screen">
        <div>
          <PlatformSidebarHyperace
            modalShown={this.state.modalShown}
            selectModal={this.selectModal}
          />
          <div>
            <CreatorUploadHypeRace account={this.props.account} />
          </div>
          {this.state.modalShown === "createAccount" ? (
            <Onboarding selectModal={this.selectModal} />
          ) : this.state.modalShown === "addFunds" ? (
            <AddFunds selectModal={this.selectModal} />
          ) : this.state.modalShown === "importFromPassword" ? (
            <ImportFromPassword selectModal={this.selectModal} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default CreatorPlatformHypeRace;
