import React, { Component } from "react";
import PlatformSidebarHyperace from "./PlatformSidebarHyperace";
import "./Videos.css";
import HyperaceVideosHome from "./HyperaceVideosHome";
import Onboarding from "./Onboarding.js";
import AddFunds from "./AddFunds.js";
import ImportFromPassword from "./ImportFromPassword";
import HyperaceConnectWalletModal from './HyperaceConnectWalletModal';
import * as encryptUtils from "./encryptDecryptUtils";
import * as loggingUtils from "./utils/loggingUtils";
import * as scriptUtils from "./utils/scriptUtils";
import axios from 'axios';

const { REACT_APP_PRIVATE_KEY } = process.env;

class HyperaceVideos extends Component {
  constructor(props) {
    super(props);
    this.selectModal = this.selectModal.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);

    this.state = {
      mintedVideos: [],
      searchText: "",
      selectedVideos: [],
      modalShown: "",
    };
  }

  async componentDidMount() {
    let mintedVideos = await encryptUtils.getMintFinishedNFTs("0"); // video media type
    mintedVideos = mintedVideos.sort((a, b) => parseInt(b.tokenId) - parseInt(a.tokenId));
    mintedVideos = mintedVideos.filter(video => {
      return parseInt(video.impressionFee) === 0;
    })
    for(let i = 0; i < mintedVideos.length; i++) {
      let video = mintedVideos[i];
      let rawAddress = video["creatorAddress"];
      if(rawAddress != null) {
        rawAddress = rawAddress.substring(0, 5) + "..." + rawAddress.substring(rawAddress.length - 5)
        video["displayedCreatorAddress"] = rawAddress;
      }
      mintedVideos[i] = video;
    }
    this.setState({
      mintedVideos,
      selectedVideos: mintedVideos,
    })
    for(let i = 0; i < mintedVideos.length; i++) {
      let video = mintedVideos[i];
      let decryptedBlob = await this.litDecrypt(video);
      video["decryptedBlobURL"] = URL.createObjectURL(new Blob([decryptedBlob]));
      video["creatorProfileImage"] = scriptUtils.getProfileImage(video["creatorAddress"]);
      mintedVideos[i] = video;
      this.setState({
        mintedVideos,
        selectedVideos: mintedVideos,
      });
    }
    mintedVideos = await encryptUtils.getHyperaceLeaderboardDataBulk(mintedVideos, this.props.account != null ? this.props.account.address : null, "uri");
    this.setState({
      mintedVideos,
      selectedVideos: mintedVideos,
    });
    for(let i = 0; i < mintedVideos.length; i++) {
      let video = mintedVideos[i];
      video["displayedCreatorAddress"] = await encryptUtils.getENS(video["creatorAddress"]);
      mintedVideos[i] = video;
      this.setState({
        mintedVideos,
        selectedVideos: mintedVideos,
      });
    }
    loggingUtils.logEvent(
      this.props.account != null ? this.props.account.address : 'wallet_unconnected_user',
      'videos',
      'videos_page_load',
      'info',
      "https://the402.xyz",
      {},
    );
  }

  async componentDidUpdate(prevProps) {
    if(this.props.account !== prevProps.account) {
      let address = null;
      if(this.props.account != null) {
        address = this.props.account.address;
      }
      let mintedVideos = this.state.mintedVideos;
      mintedVideos = await encryptUtils.getHyperaceLeaderboardDataBulk(mintedVideos, this.props.account != null ? this.props.account.address : null, "uri");
      this.setState({
        mintedVideos,
        selectedVideos: mintedVideos,
      });
    }
  }

  async litDecrypt(video) {
    let authSig = await encryptUtils.getAuthSigDedicatedWallet(REACT_APP_PRIVATE_KEY);
    let item = video;

    let ppvMetadata = await axios.get(item["uri"]);
    let symmetricKeyRaw = ppvMetadata.data.encryptedSymmetricKey;
    let encryptedSymmetricKey = Object.keys(symmetricKeyRaw).map(function(key){
        return symmetricKeyRaw[key];
    });
    encryptedSymmetricKey = Uint8Array.from(encryptedSymmetricKey);
    let encryptedBlob = await fetch(ppvMetadata.data.encryptedURL).then(res => res.blob());
    let decryptedBlob = await encryptUtils.decryptSong(encryptedSymmetricKey, encryptedBlob, item["authUUID"], authSig);
    return decryptedBlob;
  }

  changeSearchText(event) {
    this.setState({
      searchText: event.target.value,
      selectedVideos: this.state.mintedVideos.filter((video) =>
        video.name.includes(event.target.value)
      ),
    });
  }

  selectModal(modal) {
    this.setState({
      modalShown: modal,
    });
  }

  render() {
    return (
      <div>
        <div>
          <PlatformSidebarHyperace
            modalShown={this.state.modalShown}
            selectModal={this.selectModal}
          />
        </div>
        <div>
          {
            this.props.account != null
              ?
            <HyperaceVideosHome
              onChangeSearchText={this.changeSearchText}
              mintedVideos={this.state.selectedVideos}
              searchText={this.state.searchText}
              address={this.props.account != null ? this.props.account.address : null}
            />
              :
            <div>
              <HyperaceConnectWalletModal/>
            </div>
          }
        </div>
        {this.state.modalShown === "createAccount" ? (
          <Onboarding selectModal={this.selectModal} />
        ) : this.state.modalShown === "addFunds" ? (
          <AddFunds selectModal={this.selectModal} />
        ) : this.state.modalShown === "importFromPassword" ? (
          <ImportFromPassword selectModal={this.selectModal} />
        ) : null}
      </div>
    );
  }
}

export default HyperaceVideos;