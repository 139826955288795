import "./CreatorUpload.css";
import FileUpload from "./FileUpload";
import CreatorUploadTitleForm from "./CreatorUploadTitleForm";
import CreatorUploadDescriptionForm from "./CreatorUploadDescriptionForm";
import HyperaceConnectWalletModal from './HyperaceConnectWalletModal';
import { useLocation, useHistory } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useState } from "react";
import * as encryptUtils from "./encryptDecryptUtils";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { contractAddress, leaderboardContractAddress, mainnetContractAddress } from "./config";
import * as mintUtil from "./mintUtils";
import * as loggingUtils from "./utils/loggingUtils";

let web3;
let usedContractAddress;
if(process.env.REACT_APP_IS_MAINNET !== "testnet") {
  web3 = createAlchemyWeb3(process.env.REACT_APP_PROD_WEBSOCKET_URL);
  usedContractAddress = mainnetContractAddress;
} else {
  web3 = createAlchemyWeb3(process.env.REACT_APP_WEBSOCKET_URL);
  usedContractAddress = contractAddress;
}
const contract = require('./artifacts/contracts/PPV.sol/PPV.json');

function CreatorUploadHypeRace(props) {
  const location = useLocation();
  const [publishInit, setPublishInit] = useState(false);

  let params = {};
  let history = useHistory();

  if (typeof location.state != "undefined") {
    params = location.state.params;
  }

  function validate() {
    if (
      params.file === undefined ||
      params.file.length === 0 ||
      params.file[0].type.indexOf("video") === -1
    ) {
      alert("Please upload a video for content");
      return false;
    }

    if (params.file.duration > 30) {
      alert("Please upload a video less than 30 seconds");
      return false;
    }

    if (params.file[0].size / 1000000 > 150) {
      alert("Please upload a video less than 150 MB");
      return false;
    }

    if (
      params.description === undefined ||
      params.description.trim() === ""
    ) {
      alert("Please provide a description");
      return false;
    }
    return true;
  }

  async function handleClickPublish() {
    loggingUtils.logEvent(
      props.account != null ? props.account.address : 'wallet_unconnected_user',
      'hyperace_upload',
      'hyperace_upload_begin',
      'info',
      "https://the402.xyz",
      {},
    );
    setPublishInit(true);
    if (!validate() || props.account === undefined) {
      setPublishInit(false);
      return;
    }

    params["holderShares"] = 0;
    params["creatorShares"] = 1;
    const authSig = await encryptUtils.getAuthSigDedicatedWallet(
      process.env.REACT_APP_PRIVATE_KEY
    );
    params["creatorAddress"] = authSig.address;

    let coverPhotoURL = "https://the402.mypinata.cloud/ipfs/QmfMFrDSodELoZq6d98jQo2ogrxqmqvYzvQa7dikN1PJKh"
    params["imagePinata"] = coverPhotoURL;
    params["image"] = coverPhotoURL;

    let authUUID = encryptUtils.getAuthUUID();
    let encryptedSongInfo = await encryptUtils.encryptSong(
      params.file,
      authUUID,
      authSig
    );

    let { encryptedSymmetricKey, encryptedBlob } = encryptedSongInfo;

    const fileType = params.file[0].type;

    let mediaType;
    if (fileType.match("audio")) {
      mediaType = 1;
    } else if (fileType.match("video")) {
      mediaType = 0;
    }

    params["mediaType"] = 0;
    params["name"] = "Hyperace Piece";
    params["creatorName"] = "Hyperace Creator";

    // upload encrypted content to pinata
    let encryptedURL = await encryptUtils.writeSongPinata(
      encryptedSongInfo,
      params.name
    );

    let ppvHash = await encryptUtils.writeMetadataPinata(
      encryptedURL,
      encryptedSymmetricKey,
      params
    );

    const ppvContract = new web3.eth.Contract(contract.abi, usedContractAddress);
    ppvContract.events.UpcomingMintPublished().on("data", async function(event) {
      let data = event.returnValues;
      if(data["authUUID"] !== authUUID) {
        return;
      }
      let nextCollectibleID = parseInt(data["nextTokenId"]);
      await mintUtil.openMint(nextCollectibleID);
      await mintUtil.mintFreePPV(nextCollectibleID, props.account.address);

      loggingUtils.logEvent(
        props.account.address,
        'hyperace_upload',
        'hyperace_upload_finish',
        'info',
        "https://the402.xyz",
        {},
      );
      history.push("/media/" + nextCollectibleID + "?autorefresh=true");
    });

    await mintUtil.publishPPV(ppvHash, props.account.address, authUUID);
  }

  function setFile(file) {
    params["file"] = file;
    window.URL = window.URL || window.webkitURL;
    var video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      params.file.duration = video.duration;
    };
    if (file.length !== 0) {
      video.src = URL.createObjectURL(file[0]);
    }
    loggingUtils.logEvent(
      props.account != null ? props.account.address : 'wallet_unconnected_user',
      'hyperace_upload',
      'hyperace_upload_set_file',
      'info',
      "https://the402.xyz",
      {},
    );
  }

  function setDescription(description) {
    params["description"] = description;
  }

  return (
    <div>
      {
        props.account != null
          ?
        <div className="max-w-lg mx-auto pt-24">
          <div>
            <div>
              <FileUpload setMedia={setFile} isHyperace={true} />
            </div>
            <CreatorUploadDescriptionForm
              setDescription={setDescription}
              params={params}
            />
          </div>
          {!publishInit && (
            <div className="mx-auto mt-8 bg-black dark:bg-white h-16 w-64 font-semibold justify-center items-center rounded-lg flex font-medium text-white dark:text-black cursor-pointer" onClick={handleClickPublish}>
              Post
            </div>
          )}
          {publishInit && (
            <div className="mx-auto mt-8 bg-black dark:bg-white h-16 w-64 font-semibold justify-center items-center rounded-lg flex font-medium text-white dark:text-black cursor-pointer">Please wait...</div>
          )}
        </div>
          :
        <div className="w-full">
          <HyperaceConnectWalletModal/>
        </div>
      }
    </div>
  );
}

export default CreatorUploadHypeRace;